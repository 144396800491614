.splash {
	max-width: map-get($grid-breakpoints, 'sm'); // @Ruslan Oct23
}

nav.sidebar {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 4em;

	@include media-breakpoint-up(lg) {
		width: 16em;
	}

	.offcanvas {
		width: 16em;
	}

	.nav {
		.nav-item {
			// obsoleted / margin-right: 0.5em;

			.nav-link {
				color: $body-color;
				// obsoleted / text-align: center;

				@include media-breakpoint-up(lg) {
					text-align: initial;
				}

				&:hover,
				&:focus {
					color: $nav-link-hover-color;
				}

				// &.disabled {
				//   color: $nav-link-disabled-color;
				// }

				// obsoleted
				// .nav-link-text {
				// 	display: none;

				// 	@include media-breakpoint-up(lg) {
				// 		display: initial;
				// 		padding-left: 0.5em;
				// 	}
				// }

				.nav-link-text {
					display: initial;
					padding-left: 0.5em;
				}
			}

			.nav-link.active {
				color: $body-bg;
				background-color: $body-color;
			}
		}
	}

	// experimental
	// .navbar-welcome .nav-item .nav-link.active {
	// 	background-color: transparent !important;
	// }

	& + main {
		max-width: 69em;

		// obsoleted / margin-left: 4em !important; // experimental / or padding?
		padding-top: 4em !important;

		@include media-breakpoint-up(lg) {
			// obsoleted /  margin-left: 16em !important;
			padding-top: 0em !important;
			padding-left: 16em + 0.5em !important;
		}
	}
}

nav.sidebar.sidebar-onboarding {
	@include media-breakpoint-up(xl) {
		width: 20em;
	}

	@include media-breakpoint-up(xxl) {
		width: 24em;
	}

	& + main {
		@include media-breakpoint-up(xl) {
			padding-left: 20em + 0.5em !important;
		}

		@include media-breakpoint-up(xxl) {
			padding-left: 24em + 0.5em !important;
		}
	}
}

.hover:hover {
	.hover-opacity-100 {
		opacity: 100% !important;
		--bs-bg-opacity: 100% !important;
	}

	// reserved
	// .hover-bg-opacity-100 {
	// 	--bs-bg-opacity: 100% !important;
	// }
}

.spinner-wrapper {
	position: relative;
	display: inline-block;
}

.spinner {
	z-index: 1000;
	position: absolute;
	display: inline-block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

// experimental
#connector-create {
	.dropdown-toggle:after {
		display: none;
	}
}

// experimental
#connector {
	// .table > :not(:first-child) {
	//   border-top-width: 1px;
	// }

	.table > tbody > tr:last-child > * {
		border-bottom-width: 0;
	}
}

#account {
	.card-active {
		@extend .border-primary;
		border-width: calc(var(--bs-border-width) * 2);
	}

	.card-active > div {
		margin-top: calc(var(--bs-border-width) * -2 + var(--bs-border-width));
		// reserved / margin-left: calc(var(--bs-border-width) * -2 + var(--bs-border-width));
	}
}
